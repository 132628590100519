<template>
  <!-- <BarricadeTape /> -->
  <div v-if="isFetchingEvents" class="max-w-container px-container">
    <LoadingIndicator class="mx-auto py-32" />
  </div>
  <div v-else class="max-w-container px-container">
    <HorizontalScroller
      class="border-b border-amff-light-gray border-opacity-25 py-4"
      :scrollIntoView="$refs[`eventTypeButton-${filters.eventType}`]?.$el"
    >
      <Button
        style="padding-left: 10px; padding-right: 10px"
        v-for="{ eventType, label } in [
          { eventType: undefined, label: $t('events.event_types.all') },
          { eventType: 'projection', label: $t('events.event_types.movies') },
          { eventType: 'concert', label: $t('events.event_types.music') },
          { eventType: 'conference', label: $t('events.event_types.talks') },
        ]"
        :key="label"
        :ref="`eventTypeButton-${eventType}`"
        :color="
          filters.eventType === eventType
            ? 'amff-black-text-underlined'
            : eventType === undefined
            ? availableEventTypesByDayAndLocation.length
              ? 'amff-black-text'
              : 'amff-gray-text'
            : availableEventTypesByDayAndLocation.includes(eventType)
            ? 'amff-black-text'
            : 'amff-gray-text'
        "
        :aria-pressed="filters.eventType === eventType"
        @click="setEventType(eventType)"
      >
        <div class="lg:text-sm">{{ label }}</div>
      </Button>
    </HorizontalScroller>

    <div
      class="
        py-8
        block
        md:flex
        items-center
        border-b border-amff-light-gray border-opacity-25
      "
    >
      <!-- Dropdown Sections -->

      <div v-if="isFilterEventTypeMovies" class="relative mr-0 md:mr-6">
        <button
          ref="dropdownSections"
          class="
            px-4
            py-3
            relative
            overflow-hidden
            border-2 border-amff-black-2024
            hover:border-amff-black
            flex
            w-full
            md:w-60
          "
          :aria-expanded="isOpenDropdownSections"
          aria-controls="aria-dropdown-sections"
          @click.prevent="isOpenDropdownSections = !isOpenDropdownSections"
        >
          <div class="absolute inset-0 opacity-60 transition-all w-full" />
          <div
            class="
              relative
              flex
              items-center
              justify-between
              w-full
              font-body
              text-amff-black-2024
            "
          >
            {{
              filters.sectionId
                ? sections.find((s) => s.id == filters.sectionId)?.title
                : $t("events.section")
            }}
            <ChevronIcon
              class="w-4 h-4 text-amff-black-2024 transition-transform"
              fill="currentColor"
              :style="
                isOpenDropdownSections
                  ? 'transform: rotate(180deg)'
                  : 'transform: rotate(0deg)'
              "
            />
          </div>
        </button>

        <div
          v-if="isOpenDropdownSections"
          id="aria-dropdown-section"
          class="
            fixed
            w-screen
            h-screen
            md:w-auto
            md:h-auto
            mt-0
            z-50
            md:absolute
            top-0
            left-0
            md:mt-16
            md:z-30
            text-amff-black
            p-6
            shadow-lg
            flex flex-col
          "
        >
          <div class="absolute inset-0 bg-amff-yellow-2024" />
          <div class="relative">
            <button
              v-for="(section, index) in sections"
              :key="index"
              class="
                block
                p-4
                w-full
                md:w-80
                bg-white
                hover:bg-amff-black
                hover:text-white
                font-display
                cursor-pointer
                text-left
              "
              :class="{
                'mt-4': index,
                'bg-amff-black text-white': section.id == filters.sectionId,
              }"
              @click="
                setFilter(
                  'sectionId',
                  section.id == filters.sectionId ? undefined : section.id
                )
              "
            >
              {{ section.title }}
            </button>
          </div>
          <div class="relative block md:hidden flex justify-center mt-10">
            <button
              class="
                w-14
                h-14
                p-4
                flex
                justify-center
                items-center
                bg-white
                rounded-full
              "
              @click="isOpenDropdownSections = false"
              type="button"
            >
              <CloseIcon class="w-6 h-6" fill="currentColor" />
            </button>
          </div>
        </div>
      </div>

      <!-- Dropdown Days -->
      <div class="relative mt-1 md:mt-0 mr-0 md:mr-6">
        <button
          ref="dropdownDay"
          class="
            px-4
            py-3
            relative
            overflow-hidden
            border-2 border-amff-black-2024
            hover:border-amff-black-2024
            flex
            w-full
            md:w-60
          "
          :aria-expanded="isOpenDropdownDay"
          aria-controls="aria-dropdown-day"
          @click.prevent="isOpenDropdownDay = !isOpenDropdownDay"
        >
          <div class="absolute inset-0 opacity-60 transition-all w-full" />
          <div
            class="
              relative
              flex
              items-center
              justify-between
              w-full
              font-body
              text-amff-black-2024
            "
          >
            {{ filters.day ? formatDay(filters.day) : $t("events.day") }}
            <ChevronIcon
              class="w-4 h-4 text-amff-black-2024 transition-transform"
              fill="currentColor"
              :style="
                isOpenDropdownDay
                  ? 'transform: rotate(180deg)'
                  : 'transform: rotate(0deg)'
              "
            />
          </div>
        </button>

        <div
          v-if="isOpenDropdownDay"
          id="aria-dropdown-day"
          class="
            fixed
            w-screen
            h-screen
            md:w-auto
            md:h-auto
            mt-0
            z-50
            md:absolute
            top-0
            left-0
            md:mt-16
            md:z-30
            text-amff-black
            p-6
            shadow-lg
            flex flex-col
          "
        >
          <div class="absolute inset-0 bg-amff-yellow-2024" />
          <div class="relative">
            <button
              v-for="(availableDay, index) in availableDays"
              :key="availableDay"
              class="
                block
                p-4
                w-full
                md:w-80
                bg-white
                hover:bg-amff-black
                hover:text-white
                font-display
                cursor-pointer
                text-left
              "
              :class="{
                'mt-4': index,
                'bg-amff-black text-white': filters.day === availableDay,
                'text-amff-gray':
                  !availableDaysByEventTypeAndEventLocation.includes(
                    availableDay
                  ),
              }"
              @click="
                setFilter(
                  'day',
                  filters.day === availableDay ? undefined : availableDay
                )
              "
            >
              {{ formatDay(availableDay) }}
            </button>
          </div>
          <div class="relative block md:hidden flex justify-center mt-10">
            <button
              class="
                w-14
                h-14
                p-4
                flex
                justify-center
                items-center
                bg-black
                rounded-full
                text-amff-black
              "
              type="button"
              @click="isOpenDropdownDay = false"
            >
              <CloseIcon class="w-6 h-6" fill="currentColor" />
            </button>
          </div>
        </div>
      </div>

      <!-- Dropdown Locations -->
      <div class="relative mt-1 md:mt-0 mr-0 md:mr-6">
        <button
          ref="dropdownLocations"
          class="
            px-4
            py-3
            relative
            overflow-hidden
            border-2 border-amff-black-2024
            hover:border-amff-black-2024
            flex
            w-full
            md:w-60
          "
          :aria-expanded="isOpenDropdownLocations"
          aria-controls="aria-dropdown-locations"
          @click.prevent="isOpenDropdownLocations = !isOpenDropdownLocations"
        >
          <div class="absolute inset-0 opacity-60 transition-all w-full" />
          <div
            class="
              relative
              flex
              items-center
              justify-between
              w-full
              font-body
              text-amff-black-2024
            "
          >
            {{
              filters.locationId
                ? locations.find((l) => l.id == filters.locationId)?.name
                : $t("events.location")
            }}
            <ChevronIcon
              class="w-4 h-4 text-amff-black-2024 transition-transform"
              fill="currentColor"
              :style="
                isOpenDropdownLocations
                  ? 'transform: rotate(180deg)'
                  : 'transform: rotate(0deg)'
              "
            />
          </div>
        </button>

        <div
          v-if="isOpenDropdownLocations"
          id="aria-dropdown-locations"
          class="
            fixed
            w-screen
            h-screen
            md:w-auto
            md:h-auto
            mt-0
            z-50
            md:absolute
            top-0
            left-0
            md:mt-16
            md:z-30
            text-amff-black
            p-6
            shadow-lg
            flex flex-col
          "
        >
          <div class="absolute inset-0 bg-amff-yellow-2024" />
          <div class="relative">
            <button
              v-for="(location, index) in locations"
              :key="location.isOpenDropdownLocations"
              class="
                block
                p-4
                w-full
                md:w-80
                bg-white
                hover:bg-amff-black
                hover:text-white
                font-display
                cursor-pointer
                text-left
              "
              :class="{
                'mt-4': index,
                'bg-amff-black text-white': location.id == filters.locationId,
                'text-amff-gray': !availableLocationsByEventTypeAndDay
                  .map((l) => l.id)
                  .includes(location.id),
              }"
              @click="
                setFilter(
                  'locationId',
                  location.id == filters.locationId ? undefined : location.id
                )
              "
            >
              {{ location.name }}
            </button>
          </div>
          <div class="relative block md:hidden flex justify-center mt-10">
            <button
              class="
                w-14
                h-14
                p-4
                flex
                justify-center
                items-center
                bg-amff-black-2024
                rounded-full
              "
              @click="isOpenDropdownDay = false"
              type="button"
            >
              <CloseIcon class="w-6 h-6" fill="currentColor" />
            </button>
          </div>
        </div>
      </div>

      <!-- Clear filters button -->
      <button
        v-if="filters.day || filters.locationId || filters.sectionId"
        class="rounded-full px-3 py-1 relative overflow-hidden h-8 mt-6 md:mt-0"
        @click="resetFilters"
      >
        <div
          class="
            absolute
            inset-0
            opacity-30
            bg-amff-yellow-2024
            transition-all
            w-full
          "
        />
        <div
          class="
            relative
            text-sm text-amff-black-2024
            hover:text-white
            font-display
          "
        >
          {{ $t("events.clear_all") }}
        </div>
      </button>
    </div>

    <div
      v-if="eventsFiltered.length"
      class="
        py-6
        grid grid-cols-1
        sm:grid-cols-1
        md:grid-cols-2
        lg:grid-cols-3
        gap-y-14 gap-x-7
      "
    >
      <EventCard
        :key="'event' + event.id"
        v-for="event in eventsFiltered"
        :event="event"
      />
    </div>
    <EmptyEventsBlock v-else />
  </div>

  <FollowFestival />
</template>
<script>
import {
  defineComponent,
  ref,
  watchEffect,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import FollowFestival from "@/components/FollowFestival.vue";
import ChevronIcon from "@/components/icons/ChevronIcon.vue";
import EmptyEventsBlock from "@/components/EmptyEventsBlock.vue";
import { useRouteWithLang } from "@/composables/useRouteWithLang";
import { useEvents } from "@/composables/useEvents";
// import BarricadeTape from "@/components/BarricadeTape.vue";
import EventCard from "@/components/EventCard.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import Button from "@/components/Button.vue";
//import sectionsData from "@/data/eventsSection.json";
import { useRouter } from "vue-router";
import {
  formatDayWithWeekdayAtStart,
  isoToTimestamp,
} from "@/utils/dateTimeFormatter";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";

const VALID_TYPES = ["movies", "music", "talks"];

export default defineComponent({
  components: {
    FollowFestival,
    // BarricadeTape,
    EventCard,
    HorizontalScroller,
    Button,
    ChevronIcon,
    LoadingIndicator,
    EmptyEventsBlock,
    CloseIcon,
  },

  props: {
    type: {
      type: String,
    },
  },
  setup() {
    const { route, currentLang } = useRouteWithLang();
    const router = useRouter();
    const {
      events,
      isFetchingEvents,
      fetchEvents,
      locations,
      sections,
      filterEvents,
    } = useEvents();
    const mapTypeEvent = {
      movies: "projection",
      music: "concert",
      talks: "conference",
    };

    const filters = ref({
      eventType: undefined,
      day: undefined,
      locationId: undefined,
      sectionId: undefined,
    });

    function setFilter(key, value) {
      filters.value = { ...filters.value, [key]: value };

      router.push({
        query: {
          ...route.query,
          [key]: value,
        },
      });
    }

    function resetFilters() {
      filters.value.locationId = undefined;
      filters.value.sectionId = undefined;
      filters.value.day = undefined;
      router.push({
        query: {},
      });
    }
    const eventsFiltered = ref([]);

    watchEffect(() => {
      filters.value.eventType = mapTypeEvent[route.params.type];
      filters.value.locationId = router.currentRoute.value.query.locationId;
      filters.value.sectionId = router.currentRoute.value.query.sectionId;
      filters.value.day = router.currentRoute.value.query.day;

      if (events.value.length) {
        eventsFiltered.value = filterEvents(filters.value);
      }
    });

    if (!events.value.length) {
      fetchEvents();
    }

    function setEventType(eventType) {
      const currentQuery = router.currentRoute.value.query;
      delete currentQuery.sectionId;

      router.push({
        to: "events",
        params: {
          type:
            Object.keys(mapTypeEvent).find(
              (k) => mapTypeEvent[k] === eventType
            ) ?? "",
        },
        query: {
          ...currentQuery,
        },
      });
    }

    const availableDays = computed(() => [
      ...new Set(events.value.map((event) => event.day)),
    ]);

    const availableDaysByEventTypeAndEventLocation = computed(() => [
      ...new Set(
        filterEvents({
          eventType: filters.value.eventType,
          locationId: filters.value.locationId,
        }).map((event) => event.day)
      ),
    ]);

    const availableLocationsByEventTypeAndDay = computed(() => [
      ...new Set(
        filterEvents({
          eventType: filters.value.eventType,
          day: filters.value.day,
        }).map((event) => event.location)
      ),
    ]);

    const availableEventTypesByDayAndLocation = computed(() => [
      ...new Set(
        filterEvents({
          day: filters.value.day,
          locationId: filters.value.locationId,
        }).map((event) => event.type)
      ),
    ]);

    function formatDay(isoDay) {
      return formatDayWithWeekdayAtStart(
        isoToTimestamp(isoDay),
        currentLang.value
      );
    }

    const dropdownDay = ref(null);
    const dropdownLocations = ref(null);
    const dropdownSections = ref(null);
    const isOpenDropdownDay = ref(false);
    const isOpenDropdownLocations = ref(false);
    const isOpenDropdownSections = ref(false);

    function closeDropdowns(e) {
      if (!dropdownDay.value.contains(e.target)) {
        isOpenDropdownDay.value = false;
      }
      if (!dropdownLocations.value.contains(e.target)) {
        isOpenDropdownLocations.value = false;
      }
      if (!dropdownSections.value?.contains(e.target)) {
        isOpenDropdownSections.value = false;
      }
    }

    const locationsComputed = computed(() => {
      return locations.value.filter((l) =>
        events.value.some((e) => e.locationId === l.id)
      );
    });

    const isFilterEventTypeMovies = computed(() => {
      return filters.value?.eventType === mapTypeEvent.movies;
    });

    onMounted(() => {
      window.addEventListener("click", closeDropdowns);
    });

    onUnmounted(() => {
      window.removeEventListener("click", closeDropdowns);
    });

    return {
      filters,
      setFilter,
      events,
      isFetchingEvents,
      setEventType,
      availableDays,
      formatDay,
      isOpenDropdownDay,
      isOpenDropdownLocations,
      isOpenDropdownSections,
      dropdownDay,
      dropdownLocations,
      dropdownSections,
      isFilterEventTypeMovies,
      eventsFiltered,
      locations: locationsComputed,
      sections,
      availableEventTypesByDayAndLocation,
      availableDaysByEventTypeAndEventLocation,
      availableLocationsByEventTypeAndDay,
      resetFilters,
    };
  },
  beforeRouteEnter(to, from, next) {
    const type = to.params.type;

    if (!type || VALID_TYPES.includes(type)) {
      next();
    } else {
      next({ to: "404", params: { lang: to.params.lang } });
    }
  },
});
</script>
