<template>
  <div class="o-horizontal-scroller">
    <button
      ref="arrowLeft"
      type="button"
      class="o-horizontal-scroller__arrow-container"
      :class="{ 'is-disabled': !hasScrollLeft }"
      style="left: 0"
      aria-hidden="true"
      tabindex="-1"
      @click="scrollLeft"
      @focus="$event.target.blur()"
    >
      <ChevronIcon fill="currentColor" class="w-4 h-4 transform rotate-90" />
    </button>
    <div
      ref="scrollableArea"
      class="o-horizontal-scroller__scrollable-area"
      :class="{
        'is-scrollable-left': hasScrollLeft,
        'is-scrollable-right': hasScrollRight,
      }"
    >
      <div
        ref="innerScrollableArea"
        class="o-horizontal-scroller__inner"
        :class="slotWrapperClass"
      >
        <slot />
      </div>
    </div>
    <button
      ref="arrowRight"
      type="button"
      class="o-horizontal-scroller__arrow-container color-inherit"
      :class="{ 'is-disabled': !hasScrollRight }"
      style="right: 0"
      aria-hidden="true"
      tabindex="-1"
      @click="scrollRight"
      @focus="$event.target.blur()"
    >
      <ChevronIcon fill="currentColor" class="w-4 h-4 transform -rotate-90" />
    </button>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import debounce from "lodash/debounce";
import ChevronIcon from "@/components/icons/ChevronIcon.vue";

export default {
  components: {
    ChevronIcon,
  },
  props: {
    /**
     * The scroll type.
     * - "screen" to scroll one screen at a time.
     * - "item-naively" to scroll one item at a time.
     *    Current implementation is naive and doesn't
     *    account for items of different sizes (that's why it has the postfix)
     */
    offsetBy: {
      type: String,
      default: "screen",
      validator: (value) => ["screen", "item-naively"].indexOf(value) !== -1,
    },
    /** The class of the inner element, the one that wraps the <slot> */
    slotWrapperClass: { type: String, required: false },
    /** Indicate that the element should be within view when the component is mounted */
    scrollIntoView: { type: HTMLElement, required: false },
  },
  data: () => ({
    /** @type {boolean} */
    hasScrollLeft: null,
    /** @type {boolean} */
    hasScrollRight: null,
  }),
  methods: {
    recalculateHasScroll() {
      /** @type {HTMLElement} scrollableArea */
      let scrollableArea = this.$refs.scrollableArea;
      if (!scrollableArea) {
        return;
      }

      this.hasScrollLeft = scrollableArea.scrollLeft > 0;

      let slotScrollWidth = scrollableArea.scrollWidth;

      let innerWidth = this.$refs.innerScrollableArea.clientWidth;
      let scrollOffsetLeft = scrollableArea.scrollLeft;

      this.hasScrollRight = slotScrollWidth > innerWidth + scrollOffsetLeft;
    },
    recalculateHasScrollDebounced: debounce(function () {
      this.recalculateHasScroll();
    }, 500),
    /** Get the ammount by which we scroll */
    getScrollBy() {
      if (this.offsetBy === "screen") {
        return this.$refs.innerScrollableArea.clientWidth;
      } else {
        let arrow = this.$refs.arrowRight || this.$refs.arrowLeft;
        let arrowWidth = arrow ? arrow.clientWidth : 0;

        return (
          this.$refs.innerScrollableArea.children[0].clientWidth * 2 +
          arrowWidth
        );
      }

      /**
       * WIP: Scroll that adjust to the with of the first item that is not
       * completely available on screen, + accounts for the width of the arrow
       * to show it perfectly in place. This is not working bc it would be
       * required to have a function for each direction
       * (this only works to the right)

      let componentScrollLeft = this.$refs.scrollableArea.scrollLeft;
      let componentWidth = this.$refs.innerScrollableArea.clientWidth;

      let arrow = this.$refs.arrowRight || this.$refs.arrowLeft;
      let arrowWidth = arrow ? arrow.clientWidth : 0;

      let items = this.$refs.innerScrollableArea.children;
      for (let item of items) {
        let itemOffsetLeft = item.offsetLeft;
        let itemWidth = item.clientWidth;
        console.log(itemOffsetLeft, itemWidth);
        console.log(componentScrollLeft, componentWidth);

        if (componentScrollLeft + componentWidth < itemOffsetLeft + itemWidth) {

            return itemOffsetLeft - componentScrollLeft - arrowWidth;
        }
      }
      */
    },
    scrollLeft() {
      const scrollBy = this.getScrollBy();
      this.$refs.scrollableArea.scrollBy(-scrollBy, 0);
      // some quick calculations to determine if the arrows should be shown, without waiting for the scroll debounced listener
      this.hasScrollLeft = this.$refs.scrollableArea.scrollLeft - scrollBy > 0;
      this.hasScrollRight = true;
    },
    scrollRight() {
      const scrollBy = this.getScrollBy();

      // some quick calculations to determine if the arrows should be shown, without waiting for the scroll debounced listener
      this.hasScrollRight =
        this.$refs.scrollableArea.scrollLeft +
          this.$refs.scrollableArea.clientWidth +
          scrollBy <
        this.$refs.scrollableArea.scrollWidth;
      this.hasScrollLeft = true;

      this.$refs.scrollableArea.scrollBy(scrollBy, 0);
    },
  },
  watch: {
    scrollIntoView(newVal) {
      if (newVal) {
        // .scrollIntoView causes vertical scroll in some cases, using .scrollTo with manual calculations
        let scrollPositionX =
          newVal.offsetLeft -
          this.$refs.scrollableArea.clientWidth / 2 +
          newVal.clientWidth / 2;
        this.$refs.scrollableArea.scrollTo(scrollPositionX, 0);
      }
    },
  },
  created() {
    window.addEventListener("resize", this.recalculateHasScrollDebounced);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.recalculateHasScrollDebounced);
    this.$refs.scrollableArea.removeEventListener(
      "scroll",
      this.recalculateHasScrollDebounced
    );
  },
  mounted() {
    this.$nextTick(() => {
      this.recalculateHasScrollDebounced();
      this.$refs.scrollableArea.addEventListener(
        "scroll",
        this.recalculateHasScrollDebounced,
        { pasive: true }
      );
    });
  },
};
</script>

<style lang="scss">
/**
 * <HorizontalScroller> vue component
 *
 * @todo Refactor this to use only utilities if possible
 *  - Use .o-row--scrollable when we can use it without adding margins to the horizontal axis of the <slot> (see FI-528)
 */

$hscroller-arrow-transition-duration: 0.2s !default;

// Container
.o-horizontal-scroller {
  position: relative;
}

// Scrollable area container
.o-horizontal-scroller__scrollable-area {
  scroll-behavior: smooth;
  overflow-y: hidden;
  overflow-x: auto;

  // Disable scrollbar
  // @link https://stackoverflow.com/a/38994837
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  transition: mask-image $hscroller-arrow-transition-duration ease-in-out;

  // State modifiers (give transparency to the limits to indicate scrollability)
  &.is-scrollable-left {
    mask-image: linear-gradient(to left, black 90%, transparent);
  }

  &.is-scrollable-right {
    mask-image: linear-gradient(to left, transparent, black 10%);
  }

  &.is-scrollable-left.is-scrollable-right {
    mask-image: linear-gradient(
      to left,
      transparent,
      black 10%,
      black 90%,
      transparent
    );
  }
}

// The inner container of the scrollable area, used to calculate width with JS
.o-horizontal-scroller__inner {
  width: auto;
  white-space: nowrap;
  position: relative; // This allows the item's .offsetLeft JS prop to work
}

// Arrow indicators. Containers of the SVG / font chevron icons
.o-horizontal-scroller__arrow-container {
  //TODO:REBASE
  background-color: transparent;
  padding: 0;

  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 10;

  opacity: 1;
  visibility: visible;
  transition: opacity $hscroller-arrow-transition-duration ease-in-out;

  // state hooks
  &.is-disabled {
    opacity: 0;
    visibility: hidden;
  }

  // TODO:LINK-UTILITIES this but using a utility class
  &:hover,
  &:focus {
    color: var(--link-color);
  }
}
</style>
