<template>
  <div
    class="
      mx-auto
      max-w-xl
      px-container
      py-8
      lg:py-16
      text-center text-amff-dark-gray
    "
  >
    <div class="mb-6 text-2xl lg:text-3xl">
      {{ $t("error.no_events_in_filter") }}
    </div>

    <div>
      {{ $t("error.try_selecting_a_different_filter") }}
    </div>
  </div>
</template>
