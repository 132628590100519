import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "max-w-container px-container py-16 lg:py-40 text-center" }
const _hoisted_2 = { class: "\n        select-none\n        mb-2\n        lg:mb-8\n        text-2xl\n        lg:text-4xl\n        font-bold\n        text-amff-black-2024\n      " }
const _hoisted_3 = { class: "select-none grid grid-cols-3 gap-8 w-40 mx-auto" }
const _hoisted_4 = {
  href: "https://www.instagram.com/explore/tags/amff2024/",
  target: "_blank",
  rel: "noopener noreferrer",
  class: "lg:transform lg:hover:scale-150 transition-transform"
}
const _hoisted_5 = {
  href: "https://twitter.com/search?q=%23amff2024",
  target: "_blank",
  rel: "noopener noreferrer",
  class: "lg:transform lg:hover:scale-150 transition-transform"
}
const _hoisted_6 = {
  href: "https://www.facebook.com/hashtag/amff2024",
  target: "_blank",
  rel: "noopener noreferrer",
  class: "lg:transform lg:hover:scale-150 transition-transform"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InstagramIcon = _resolveComponent("InstagramIcon")
  const _component_TwitterIcon = _resolveComponent("TwitterIcon")
  const _component_FacebookIcon = _resolveComponent("FacebookIcon")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("home.follow_festival_at")), 1),
    _createVNode("div", {
      class: "\n        FollowFestival__hashtag\n        mb-6\n        lg:mb-12\n        text-6xl\n        lg:text-9xl\n        font-display\n        text-amff-yellow-2024\n      ",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleHashtagClick && _ctx.handleHashtagClick(...args)))
    }, " #AMFF2024 "),
    _createVNode("div", _hoisted_3, [
      _createVNode("a", _hoisted_4, [
        _createVNode(_component_InstagramIcon, {
          class: "w-8 h-8",
          fill: "amff-black"
        })
      ]),
      _createVNode("a", _hoisted_5, [
        _createVNode(_component_TwitterIcon, {
          class: "w-8 h-8",
          fill: "amff-black"
        })
      ]),
      _createVNode("a", _hoisted_6, [
        _createVNode(_component_FacebookIcon, {
          class: "w-8 h-8",
          fill: "amff-black"
        })
      ])
    ])
  ]))
}