<template>
  <AnchorLink
    :to="{
      name: 'event',
      params: { type: event.type, slug: event.slug },
    }"
    class="border-4 border-amff-black-2024 pb-3 text-amff-black-2024"
    :class="{
      'md:hover:bg-amff-black md:hover:border-black md:hover:text-amff-white':
        isHover,
    }"
    @mouseover="!isMobile ? (isHover = true) : false"
    @mouseleave="!isMobile ? (isHover = false) : false"
  >
    <div class="relative fill-post-image bg-amff-dark-gray block">
      <EventImage
        v-if="event.imageCard"
        :path="event.imageCard"
        class="
          absolute
          inset-0
          object-cover
          w-full
          h-full
          transition
          duration-200
          ease-in-out
        "
        :class="{ 'opacity-0': !showImage }"
        loading="lazy"
        @load="showImage = true"
      />
      <div
        v-if="event.accessType === 'full' || event.accessType === 'early_full'"
        class="absolute top-4 left-4"
      >
        <Label color="amff-white">
          {{ event.getAccessTypeFormatted(currentLang) }}
        </Label>
      </div>
      <div
        v-else-if="
          event.accessType === 'limited' || event.accessType === 'free'
        "
        class="absolute top-4 left-4"
      >
        <Label color="amff-white">
          {{ $t("events.book_tickets") }}
        </Label>
      </div>
      <div
        v-else-if="event.accessType === 'purchase'"
        class="absolute top-4 left-4"
      >
        <Label color="amff-white">
          {{ $t("events.buy_tickets") }}
        </Label>
      </div>
    </div>
    <div class="p-6 relative pb-12">
      <div class="mb-3 flex">
        <Label :color="!isHover ? 'amff-black' : 'amff-white'" class="mr-2">
          {{ $t(eventTypeLabel) }}
        </Label>
        <Label :color="!isHover ? '' : 'amff-white-text'" class="truncate">
          {{ event.subtitle }}
        </Label>
      </div>
      <div class="text-2xl lg:text-3xl font-bold">
        {{ event.title }}
      </div>
      <div
        class="mt-3 font-thin lg:text-lg w-full overflow-ellipsis line-clamp-2"
      >
        {{ eventDescription }}
      </div>
    </div>
  </AnchorLink>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import AnchorLink from "@/components/AnchorLink.vue";
import EventImage from "@/components/EventImage.vue";
import Label from "@/components/Label.vue";
import { useRouteWithLang } from "@/composables/useRouteWithLang";
import { useMediaQuery } from "@vueuse/core";

export default defineComponent({
  // eslint-disable-next-line vue/no-unused-components
  components: { AnchorLink, EventImage, Label },
  props: {
    event: {
      type: Object,
    },
  },
  setup(props) {
    const showImage = ref(false);
    const { route, currentLang } = useRouteWithLang();
    const isHover = ref(false);
    const isMobile = useMediaQuery("(max-width: 1065px)");

    const eventTypeLabel = computed(() => {
      if (props.event.type === "concert") {
        return "events.event_types.music";
      }
      if (props.event.type === "projection") {
        return "events.event_types.movie";
      }
      if (props.event.type === "conference") {
        return "events.event_types.talk";
      }

      return "events.event_types.all";
    });

    const eventDescription = computed(() => {
      var html = ref(props.event.description);
      var div = document.createElement("div");
      div.innerHTML = html.value;
      var description = div.textContent || div.innerText || "";
      return description;
    });
    function openAccessLink() {
      window.open(props.event.accessLink, "_blank");
    }

    return {
      showImage,
      eventTypeLabel,
      route,
      currentLang,
      eventDescription,
      openAccessLink,
      isHover,
      isMobile,
    };
  },
});
</script>
