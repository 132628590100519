
import { defineComponent } from "vue";
import FacebookIcon from "@/components/icons/FacebookIcon.vue";
import InstagramIcon from "@/components/icons/InstagramIcon.vue";
import TwitterIcon from "@/components/icons/TwitterIcon.vue";

export default defineComponent({
  components: {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
  },

  setup() {
    const handleHashtagClick = (e: MouseEvent) => {
      const selection = window.getSelection();

      if (selection) {
        selection.selectAllChildren(e.target as HTMLElement);
      }
    };

    return {
      handleHashtagClick,
    };
  },
});
